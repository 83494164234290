import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingEn50518Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'EN 50518'

  const description =
    '<b>ING GROUP affianca gli ISTITUTI DI VIGILANZA per tutti i requisiti richiesti da D.M. 269/2010 – D.M. 115/2014 – REGOLAMENTO ATTUATIVO DM 115.2014 DEL CAPO DELLA POLIZIA 2015!</b><br> Oggi ING GROUP è la principale struttura in ITALIA capace di assicurare agli Istituti di Vigilanza quanto richiesto dal Ministero dell’Interno.<br> Abbiamo coordinato la progettazione e la certificazione secondo la UNI 11068 e la EN 50518 di 30 centrali operative nelle provincie di Vicenza, Firenze, Milano, Torino, Belluno, Monza e Brianza, Trento, Bologna, Bergamo, Sassari, Alessandria, Caserta.<br> Disponiamo di internal SENIOR SECURITY MANAGER certificato in conformità alla UNI 10459:2017 da ICIM Spa.<br> ING GROUP affianca nella progettazione e certificazione delle CENTRALI OPERATIVE i principali Istituti di Vigilanza in Italia'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
